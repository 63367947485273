import service from './baseService';
import { getCompanyId } from '../config';

export const getTourSpecialRequirementTypesService = () => {
    const params = {
        companyID: getCompanyId(),
    };
    return service.get(`/tour-special-requirement-types`, { params });
};

export const createTourSpecialRequirementTypesService = (payload) => {
    return service.post(`/tour-special-requirement-types`, payload, {
        params: { companyID: getCompanyId() },
    });
};

export const editTourSpecialRequirementTypesService = (id, data) => {
    return service.put(`/tour-special-requirement-types/${id}`, data, {
        params: { companyID: getCompanyId() },
    });
};

export const deleteTourSpecialRequirementTypesService = (id) => {
    return service.delete(`/tour-special-requirement-types/${id}`, {
        params: { companyID: getCompanyId() },
    });
};
