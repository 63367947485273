import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Form, Button, FormControl } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import { createTransactionService, getCountriesService, getStatesService } from '../../services/transactionsService';
import { makeBookingPortalPaymentService } from '../../services/authorizeNetService';
import { formatDateRange, roundTo, validateEmail } from '../../utils/helper';
import Select from 'react-select';
import _ from 'lodash';
import './index.scss';
import { createTicketsService } from '../../services/toursServices';
import Loading from '../Loading';
import { getTourSpecialRequirementTypesService } from '../../services/TourSpecialRequirementTypesService';

const TourPayment = ({ tripSetting, ticketQuantities, ticketUpgrades, selectedTour, width, setFormState }) => {
    const { register, handleSubmit, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [ip, setIP] = useState('');
    const [tickets, setTickets] = useState([]);
    const [errors, setErrors] = useState([]);
    const [copyPassengerDetails, setCopyPassengerDetails] = useState(false);
    const [selectedPrimaryContact, setSelectedPrimaryContact] = useState(0);
    const [tourSpecialRequirementTypes, setTourSpecialRequirementTypes] = useState([]);
    useEffect(() => {
        (async () => {
            const ticketDataSet = [];
            ticketQuantities.forEach((tq) => {
                [...Array(tq.selected)].forEach((t, i) => {
                    ticketDataSet.push({
                        price: tq.price,
                        salesTax: tq.salesTax,
                        ticketType: tq.ticketType,
                        tourTicketID: tq.tourTicketID,
                        availableTicketID: tq.availableTicketID,
                        optionalUpgrades: [],
                        requiredUpgrades: [],
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        primaryContact: i === 0,
                        errors: [],
                        tourSpecialRequirementTypeID: null,
                    });
                });
            });
            setTickets(ticketDataSet);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketQuantities]);

    useEffect(() => {
        (async () => {
            try {
                const fetchedTourSpecialRequirementTypes = await getTourSpecialRequirementTypesService();
                setTourSpecialRequirementTypes(fetchedTourSpecialRequirementTypes?.data);
                const fetchedStates = await getStatesService();
                setStates(fetchedStates?.data);
                const fetchedCountries = await getCountriesService();
                setCountries(fetchedCountries?.data);
            } catch (e) {
                console.log('e', e);
                setLoading(false);
            }
        })();
    }, []);

    const getIpAddress = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        setIP(res.data.IPv4);
    };

    useEffect(() => {
        //passing getIpAddress method to the lifecycle method
        getIpAddress();
    }, []);

    const handleSubmitPayment = async (e) => {
        e.preventDefault();
        await handleSubmit(async (data) => {
            try {
                setLoading(true);

                // Check Ticket & Passenger Data
                if (!checkTicketsAreValid()) {
                    setLoading(false);
                    return;
                }

                // Check Payment Data
                const errorArr = [];
                if (!data.firstName) errorArr.push('firstName');
                if (!data.lastName) errorArr.push('lastName');
                if (!data.email) errorArr.push('email');
                if (!data.street) errorArr.push('street');
                if (!data.city) errorArr.push('city');
                if (!data.state) errorArr.push('state');
                if (!data.zip) errorArr.push('zip');
                if (!data.cardExpiration) errorArr.push('cardExpiration');
                if (!data.cardNumber) errorArr.push('cardNumber');
                if (!data.cvv) errorArr.push('cvv');
                setErrors([...errorArr]);

                if (!errorArr.length) {
                    if (data && data.cardNumber && data.cardNumber.length < 15) {
                        setLoading(false);
                        return swal({
                            title: 'Card Number Error',
                            text: 'Please enter a valid Card Number',
                            icon: 'error',
                        });
                    }

                    const trimTickets = tickets.map((t) => ({
                        ...t,
                        firstName: t.firstName.trim(),
                        lastName: t.lastName.trim(),
                        email: t.email.trim(),
                        phone: t.phone.trim(),
                    }));

                    const ticketRequest = await createTicketsService({
                        ticketData: trimTickets,
                        vehicleAssignmentID: selectedTour?.id,
                        companyID: tripSetting.companyID,
                    });

                    const ticketResponse = ticketRequest?.data;

                    const surchargeAmount = calculateSurchargeAmount();
                    const transactionAmount = calculateGrandTotal();
                    const transactionRequestData = {
                        ...data,
                        ticketPurchaseOrderID: ticketResponse?.ticketPurchaseOrder?.id, // from ticketRequest
                        amount: transactionAmount,
                        surchargeAmount: surchargeAmount > 0 ? surchargeAmount : null,
                        transactionTypeID: 3,
                        companyID: tripSetting.companyID,
                        date: moment().format('YYYY-MM-DD'),
                    };
                    let transactionRequest = await createTransactionService(transactionRequestData);
                    const { transaction, surchargeTransaction = null } = transactionRequest.data;

                    const paymentRequestData = {
                        ...transactionRequestData,
                    };
                    paymentRequestData.companyID = tripSetting.companyID;
                    paymentRequestData.orderID = transaction.id;
                    paymentRequestData.ecomind = 'E';
                    paymentRequestData.customerID = ip;
                    if (surchargeTransaction) paymentRequestData.surchargeTransactionID = surchargeTransaction.id;

                    let response = await makeBookingPortalPaymentService(paymentRequestData, tripSetting.companyID);

                    response = response.data;
                    if (typeof response == 'undefined') {
                        window.swal(
                            'Transaction Failed',
                            "We're Sorry. We have experienced an unknown error. Please wait a few minutes and try again. If the issue persists, please get in touch with us.",
                            'error'
                        );
                        setLoading(false);
                        return;
                    } else if (response.errors && response.errors.length > 0) {
                        swal({ title: 'Transaction Declined', text: response.errors[0].msg, icon: 'error' });
                        setLoading(false);
                        return;
                    } else if (response.error) {
                        swal({ title: 'Transaction Declined', text: response.error, icon: 'error' });
                        setLoading(false);
                        return;
                    } else if (response.Result === 'Declined') {
                        swal({ title: 'Transaction Declined', text: response.Status, icon: 'error' });
                        setLoading(false);
                        return;
                    } else if (response.code === 'A') {
                        setFormState({
                            stage: 2,
                            id: transaction.id,
                            amount: transaction.amount,
                        });
                    }
                }
                setLoading(false);
            } catch (e) {
                console.log('error: ', e);
                swal({
                    title: 'There was an error processing your tickets. Please try again.',
                    icon: 'error',
                });
                setLoading(false);
                return;
            }
        })();
    };

    const handleEditTicket = (field, value, updateIndex) => {
        const updatedTicketInfo = tickets.map((t, i) => {
            if (i !== updateIndex) {
                if (field === 'primaryContact' && value) {
                    return { ...t, primaryContact: false };
                }
                return t;
            }
            return {
                ...t,
                [field]: value,
                errors: [],
            };
        });
        setErrors([]);
        setTickets(updatedTicketInfo);
    };

    console.log(tickets);

    const handleUpgradeSelect = (value, updateIndex, actionMeta = null) => {
        const updatedTicketInfo = tickets.map((t, i) => {
            if (i !== updateIndex) return t;

            if (actionMeta.action === 'select-option' || actionMeta.action === 'remove-value') {
                return { ...t, optionalUpgrades: value, errors: [] };
            } else if (actionMeta.action === 'clear') {
                return { ...t, optionalUpgrades: [], errors: [] };
            }
        });

        setErrors([]);
        setTickets(updatedTicketInfo);
    };

    const handleRequiredUpgradeSelect = (value, updateIndex, actionMeta = null) => {
        const updatedTicketInfo = tickets.map((t, i) => {
            if (i !== updateIndex) return t;

            if (actionMeta.action === 'select-option' || actionMeta.action === 'remove-value') {
                if (t?.requiredUpgrades?.length) {
                    return {
                        ...t,
                        requiredUpgrades: t.requiredUpgrades.map((tReqUpgrades) => {
                            if (tReqUpgrades.upgradeTypeID !== value.upgradeTypeID) return t;
                            else {
                                return value;
                            }
                        }),
                        errors: [],
                    };
                } else {
                    return {
                        ...t,
                        requiredUpgrades: [value],
                        errors: [],
                    };
                }
            } else if (actionMeta.action === 'clear') {
                return { ...t, requiredUpgrades: [], errors: [] };
            }
        });

        setErrors([]);
        setTickets(updatedTicketInfo);
    };

    const getRequiredUpgradesByUpgradeTypeID = (ticket) => {
        const requiredUpgradeMap = ticketUpgrades?.required?.filter(
            (rtu) => rtu.availableTicketID === ticket.availableTicketID
        );

        const requiredUpgradesByUpgradeTypeID = requiredUpgradeMap.reduce((accumulator, currVal) => {
            /* 
                This organizes the required upgrades into categories by their ID so we can create a dropdown for each.
                i.e. {
                    id: [...],
                    id: [...]
                }
            */
            currVal.label = `${currVal.availableTicketUpgrade} ${Number(currVal.price) > 0 ? `($${currVal.price})` : ''}`;
            currVal.value = currVal.availableTicketUpgradeID;
            if (accumulator[currVal?.upgradeTypeID]) accumulator[currVal?.upgradeTypeID].push(currVal);
            else accumulator[currVal?.upgradeTypeID] = [currVal];
            return accumulator;
        }, {});

        return requiredUpgradesByUpgradeTypeID;
    };

    const checkTicketsAreValid = () => {
        /* 
            1. There needs to be one main contact chosen.
            2. The main contact needs all personal info fields filled out.
            3. Secondary contacts just need first and last name.
            4. If there are required upgrades, all passengers need each req upgrade to be chosen.
            5. Proper email address regex (if not already there by default).
        */
        const ticketsClone = _.cloneDeep(tickets);

        const mainContact = ticketsClone.find((t) => t.primaryContact);
        if (!mainContact) {
            // This is an error within the scope of the entire form.
            setErrors([...errors, 'mainContact']);
            return false;
        }

        if (!mainContact.email || !validateEmail(mainContact.email))
            mainContact.errors = [...mainContact.errors, 'email'];
        if (!mainContact.firstName) mainContact.errors = [...mainContact.errors, 'firstName'];
        if (!mainContact.lastName) mainContact.errors = [...mainContact.errors, 'lastName'];
        if (!mainContact.phone) mainContact.errors = [...mainContact.errors, 'phone'];

        const secondaryContacts = ticketsClone.filter((t) => !t.primaryContact);
        for (let contact of secondaryContacts) {
            if (!contact.firstName) contact.errors = [...contact.errors, 'firstName'];
            if (!contact.lastName) contact.errors = [...contact.errors, 'lastName'];
            if (contact.email && !validateEmail(contact.email)) [...contact.errors, 'email'];
        }
        // Check if there are required upgrades in the first place
        if (ticketUpgrades?.required?.length) {
            for (let ticket of ticketsClone) {
                // Gather each different upgrade type ID (each unique ID = one dropdown)
                const reqUpgrades = getRequiredUpgradesByUpgradeTypeID(ticket);
                const reqUpgradeTypeIDs = Object.keys(reqUpgrades);

                for (let reqUpgradeTypeID of reqUpgradeTypeIDs) {
                    // If we can't find a req upgrade on the ticket that matches an upgradeTypeID, there is an error.
                    if (
                        !ticket?.requiredUpgrades.some((tReqUpgrade) => tReqUpgrade.upgradeTypeID !== reqUpgradeTypeID)
                    ) {
                        ticket.errors = [...ticket.errors, 'requiredUpgrades'];
                    }
                }
            }
        }

        if (ticketsClone.some((ticket) => ticket.errors?.length > 0)) {
            setTickets(ticketsClone);
            return false;
        }

        return true;
    };

    const calculateTourTicketBasePrice = (ticket) => {
        const basePrice = Number(ticket.price);
        const optionalUpgradeTotal = ticket.optionalUpgrades?.reduce((sum, upgrade) => {
            return sum + Number(upgrade.price);
        }, 0);
        const requiredUpgradeTotal = ticket.requiredUpgrades?.reduce((sum, upgrade) => {
            return sum + Number(upgrade.price);
        }, 0);
        return roundTo(basePrice + optionalUpgradeTotal + requiredUpgradeTotal, 2).toFixed(2);
    };

    const calculateTourTicketTaxPrice = (ticket) => {
        const baseTax = Number(ticket.salesTax);
        const optionalUpgradeTax = ticket.optionalUpgrades?.reduce((sum, upgrade) => {
            return sum + Number(upgrade.salesTax);
        }, 0);
        const requiredUpgradeTax = ticket.requiredUpgrades?.reduce((sum, upgrade) => {
            return sum + Number(upgrade.salesTax);
        }, 0);
        return roundTo(baseTax + optionalUpgradeTax + requiredUpgradeTax, 2).toFixed(2);
    };

    const calculateTourTicketFullPrice = (ticket) =>
        roundTo(
            Number(calculateTourTicketBasePrice(ticket)) +
                Number(tripSetting.bookingFee) +
                Number(calculateTourTicketTaxPrice(ticket)),
            2
        ).toFixed(2);

    const calculateAllTicketsPrice = () => {
        let sum = 0;
        for (let ticket of tickets) {
            sum += Number(calculateTourTicketFullPrice(ticket));
        }
        return roundTo(sum, 2).toFixed(2);
    };

    const calculateSurchargeAmount = () => {
        if (tripSetting.creditCardSurchargePercentage) {
            const creditCardSurchargePercentage = roundTo(
                Number(tripSetting.creditCardSurchargePercentage) / 100,
                2
            ).toFixed(2);
            return roundTo(calculateAllTicketsPrice() * creditCardSurchargePercentage, 2).toFixed(2);
        } else return 0;
    };

    const calculateGrandTotal = () =>
        (Number(calculateAllTicketsPrice()) + Number(calculateSurchargeAmount())).toFixed(2);

    const handleCopyPassengerDetails = (e) => {
        setCopyPassengerDetails(!e?.target?.checked);
        if (e?.target?.checked) {
            const passenger = tickets.find((t) => t.primaryContact);
            if (passenger) {
                setValue('firstName', passenger.firstName);
                setValue('lastName', passenger.lastName);
                setValue('email', passenger.email);
            }
        }
    };

    const renderTicketUpgrades = (t, i) => {
        if (ticketUpgrades?.available?.find((tUp) => tUp.availableTicketID === t.availableTicketID)) {
            return (
                <Col sm={12} md={4} lg={4}>
                    <FormGroup>
                        <Form.Label>Upgrades</Form.Label>
                        <Select
                            name="upgrades"
                            value={t.optionalUpgrades}
                            options={ticketUpgrades?.available
                                ?.filter((tUp) => tUp.availableTicketID === t.availableTicketID)
                                .map((tu) => ({
                                    ...tu,
                                    label: `${tu.availableTicketUpgrade} ($${tu.price})`,
                                    value: tu.availableTicketUpgradeID,
                                }))}
                            onChange={(selectedOption, actionMeta) =>
                                handleUpgradeSelect(selectedOption, i, actionMeta)
                            }
                            isMulti
                            isClearable={true}
                        />
                    </FormGroup>
                </Col>
            );
        } else {
            return <></>;
        }
    };
    return loading ? (
        <Loading loading={loading} />
    ) : (
        <Row>
            <Col md={8} xs={12} className="d-flex justify-content-center">
                <div className="w-100 payment-form-main">
                    <form
                        className="payment-form-wrapper"
                        onSubmit={(e) => {
                            handleSubmitPayment(e);
                        }}
                    >
                        <Form.Label
                            className="text-uppercase payment-form-heading"
                            style={{ color: `${tripSetting.textColor}` }}
                        >
                            Ticket Passenger Information
                        </Form.Label>
                        <div className="payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                            {tickets.map((t, i, tickets) => {
                                const requiredUpgradesByUpgradeTypeID = getRequiredUpgradesByUpgradeTypeID(t);
                                return (
                                    <Row key={t.id} className="px-4">
                                        <Row className="w-100">
                                            <Col sm={12} md={12} lg={12}>
                                                <h5 style={{ color: `${tripSetting.textColor}` }}>{t.ticketType}</h5>
                                            </Col>
                                            <Row>
                                                <Col xs={12} md={3} lg={3}>
                                                    <FormGroup>
                                                        <Form.Label>First Name*</Form.Label>
                                                        <Form.Control
                                                            id="firstName"
                                                            name="firstName"
                                                            placeholder="First Name"
                                                            value={t.firstName}
                                                            onChange={(e) => {
                                                                handleEditTicket('firstName', e?.target?.value, i);
                                                            }}
                                                            isInvalid={t?.errors?.includes('firstName')}
                                                            type="text"
                                                        />
                                                        <FormControl.Feedback id="firstName" type="invalid">
                                                            Please Enter First Name
                                                        </FormControl.Feedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={3} lg={3}>
                                                    <FormGroup>
                                                        <Form.Label>Last Name*</Form.Label>
                                                        <Form.Control
                                                            id="lastName"
                                                            placeholder="Last Name"
                                                            name="lastName"
                                                            value={t.lastName}
                                                            onChange={(e) => {
                                                                handleEditTicket('lastName', e?.target?.value, i);
                                                            }}
                                                            isInvalid={t?.errors?.includes('lastName')}
                                                            type="text"
                                                        />
                                                        <FormControl.Feedback id="lastName" type="invalid">
                                                            Please Enter Last Name
                                                        </FormControl.Feedback>
                                                    </FormGroup>
                                                </Col>

                                                <Col xs={12} md={3} lg={3}>
                                                    <FormGroup>
                                                        <Form.Label>{`Email Address${t.primaryContact ? '*' : ''}`}</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            name={`email`}
                                                            value={t.email}
                                                            placeholder="Email Address"
                                                            isInvalid={t?.errors?.includes('email')}
                                                            onChange={(e) => {
                                                                handleEditTicket('email', e?.target?.value, i);
                                                            }}
                                                        />
                                                        <FormControl.Feedback id="email" type="invalid">
                                                            Please Enter a Valid Email
                                                        </FormControl.Feedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={3} lg={3}>
                                                    <FormGroup>
                                                        <Form.Label>{`Phone${t.primaryContact ? '*' : ''}`}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="phone"
                                                            value={t.phone}
                                                            placeholder="Phone Number"
                                                            isInvalid={t?.errors?.includes('phone')}
                                                            onChange={(e) => {
                                                                handleEditTicket('phone', e?.target?.value, i);
                                                            }}
                                                        />
                                                        <FormControl.Feedback id="email" type="invalid">
                                                            Please Enter a Phone Number
                                                        </FormControl.Feedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="w-100">
                                                {Object.entries(requiredUpgradesByUpgradeTypeID).map(
                                                    ([key, value], j) => {
                                                        return (
                                                            <Col key={j} sm={12} md={4} lg={4}>
                                                                <FormGroup>
                                                                    <Form.Label>
                                                                        Select {`${value[j]?.upgradeType}*`}
                                                                    </Form.Label>
                                                                    <Select
                                                                        name="requiredUpgrades"
                                                                        options={value}
                                                                        isClearable={true}
                                                                        onChange={(selectedOption, actionMeta) =>
                                                                            handleRequiredUpgradeSelect(
                                                                                selectedOption,
                                                                                i,
                                                                                actionMeta
                                                                            )
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                                {renderTicketUpgrades(t, i)}
                                                {tourSpecialRequirementTypes.length > 0 && (
                                                    <Col xs={12} md={4} lg={4}>
                                                        <FormGroup>
                                                            <Form.Label>Special Requirements</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                id="tourSpecialRequirementTypeID"
                                                                name="tourSpecialRequirementTypeID"
                                                                onChange={(e) => {
                                                                    handleEditTicket(
                                                                        'tourSpecialRequirementTypeID',
                                                                        e?.target?.value,
                                                                        i
                                                                    );
                                                                }}
                                                            >
                                                                <option value="" selected>
                                                                    No Selection
                                                                </option>
                                                                {tourSpecialRequirementTypes.map((r) => (
                                                                    <option key={r.id} value={r.id}>
                                                                        {`${r.name}`}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Col xs={12} md={6} lg={6}>
                                                <Form.Check
                                                    className={`my-1 text-align-center`}
                                                    type="checkbox"
                                                    checked={selectedPrimaryContact === i}
                                                    name="mainContact"
                                                    label="Set as Primary Contact"
                                                    onChange={(e) => {
                                                        // The form coerces the boolean false to 'false', so need to check for that happening
                                                        const value = e.target.checked;
                                                        setSelectedPrimaryContact(i);
                                                        if (value) handleEditTicket('primaryContact', value, i);
                                                    }}
                                                    style={{
                                                        display: `${tickets.length === 1 ? 'none' : 'block'}`,
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        {i !== tickets.length - 1 && <hr className="hr-line" />}
                                    </Row>
                                );
                            })}
                            <p className="font-italic mt-1 mb-0">*Field Required</p>
                        </div>
                        <Form.Label
                            className=" text-uppercase payment-form-heading"
                            style={{ color: `${tripSetting.textColor}` }}
                        >
                            Billing Information
                        </Form.Label>
                        <div className="payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                            <Row>
                                <Col xs={12} md={6} lg={6}>
                                    <Form.Check
                                        className={`my-1 text-align-center`}
                                        type="checkbox"
                                        value={copyPassengerDetails}
                                        name="mainContact"
                                        label="Copy Main Passenger Info"
                                        onChange={handleCopyPassengerDetails}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <FormGroup className="d-flex payment-form-group">
                                        <Form.Label
                                            className="payment-form-label"
                                            style={{ color: `${tripSetting.textColor}` }}
                                        >
                                            NAME*
                                        </Form.Label>
                                        <div className="d-flex flex-column payment-form-inpu-group w-100">
                                            <Form.Control
                                                type="text"
                                                id="firstName"
                                                name="firstName"
                                                placeholder="First Name"
                                                {...register('firstName')}
                                                isInvalid={errors.includes('firstName')}
                                                className="payment-form-input"
                                            />
                                            <FormControl.Feedback id="description" type="invalid">
                                                First Name is required
                                            </FormControl.Feedback>

                                            <Form.Control
                                                type="text"
                                                id="lastName"
                                                name="lastName"
                                                placeholder="Last Name"
                                                {...register('lastName')}
                                                isInvalid={errors.includes('lastName')}
                                                className="payment-form-input"
                                            />
                                            <FormControl.Feedback id="description" type="invalid">
                                                Last Name is required
                                            </FormControl.Feedback>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup className="d-flex payment-form-group">
                                        <Form.Label
                                            className="payment-form-label"
                                            style={{ color: `${tripSetting.textColor}` }}
                                        >
                                            EMAIL*
                                        </Form.Label>
                                        <div className="d-flex flex-column payment-form-inpu-group w-100">
                                            <Form.Control
                                                type="text"
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                {...register('email')}
                                                isInvalid={errors.includes('email')}
                                                className="payment-form-input"
                                            />
                                            <FormControl.Feedback id="description" type="invalid">
                                                Email is required
                                            </FormControl.Feedback>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup className="d-flex payment-form-group">
                                        <Form.Label
                                            className="payment-form-label"
                                            style={{ color: `${tripSetting.textColor}` }}
                                        >
                                            BILLING ADDRESS*
                                        </Form.Label>
                                        <div className="d-flex flex-column payment-form-inpu-group w-100">
                                            <Form.Control
                                                type="text"
                                                id="street"
                                                name="street"
                                                required={true}
                                                placeholder="Address Line 1"
                                                {...register('street')}
                                                isInvalid={errors.includes('street')}
                                                className="payment-form-input"
                                            />
                                            <FormControl.Feedback id="description" type="invalid">
                                                Street Address is required
                                            </FormControl.Feedback>

                                            <Form.Control
                                                type="text"
                                                id="street2"
                                                name="street2"
                                                placeholder="Address Line 2"
                                                {...register('street2')}
                                                className="payment-form-input"
                                            />
                                            <Form.Control
                                                type="text"
                                                id="city"
                                                name="city"
                                                required={true}
                                                placeholder="City"
                                                {...register('city')}
                                                isInvalid={errors.includes('city')}
                                                className="payment-form-input"
                                            />
                                            <FormControl.Feedback id="description" type="invalid">
                                                City is required
                                            </FormControl.Feedback>

                                            <div className="d-flex payment-form-select-group">
                                                <Form.Control
                                                    as="select"
                                                    id="state"
                                                    name="state"
                                                    placeholder="State"
                                                    className="payment-form-select"
                                                    {...register('state')}
                                                    isInvalid={errors.includes('state')}
                                                >
                                                    {states.length &&
                                                        states.map((r, i) => (
                                                            <option key={i} value={r.abbreviation}>
                                                                {r.abbreviation}
                                                            </option>
                                                        ))}
                                                </Form.Control>
                                                <FormControl.Feedback id="description" type="invalid">
                                                    State is required
                                                </FormControl.Feedback>

                                                <Form.Control
                                                    type="text"
                                                    id="zip"
                                                    name="zip"
                                                    required={true}
                                                    placeholder="Zip"
                                                    {...register('zip')}
                                                    isInvalid={errors.includes('zip')}
                                                    className="payment-form-select"
                                                />
                                                <FormControl.Feedback id="description" type="invalid">
                                                    Zip Name is required
                                                </FormControl.Feedback>

                                                <Form.Control
                                                    as="select"
                                                    id="country"
                                                    name="country"
                                                    className="payment-form-select"
                                                    placeholder="country"
                                                    {...register('country')}
                                                >
                                                    {countries &&
                                                        countries.length > 0 &&
                                                        countries.map((r, i) => (
                                                            <option key={i} value={r.alpha2Code}>
                                                                {r.name}
                                                            </option>
                                                        ))}
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>

                        <Form.Label
                            className=" text-uppercase payment-form-heading"
                            style={{ color: `${tripSetting.textColor}` }}
                        >
                            Payment Information
                        </Form.Label>
                        <div className="payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                            <Row>
                                <Col xs={3}>
                                    <Form.Label
                                        className="payment-form-label"
                                        style={{ color: `${tripSetting.textColor}` }}
                                    >
                                        CREDIT CARD*
                                    </Form.Label>
                                </Col>
                                <Col xs={9}>
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup className="d-flex payment-form-group mb-1">
                                                <div className="d-flex flex-column payment-form-inpu-group w-100">
                                                    <Form.Control
                                                        type="text"
                                                        id="cardNumber"
                                                        name="cardNumber"
                                                        required={true}
                                                        maxLength={16}
                                                        placeholder="Card Number*"
                                                        {...register('cardNumber')}
                                                        isInvalid={errors.includes('cardNumber')}
                                                        className="payment-form-input"
                                                        onChange={() => setErrors([])}
                                                    />
                                                    <FormControl.Feedback id="description" type="invalid">
                                                        Please Enter a Card Number
                                                    </FormControl.Feedback>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <div className="d-flex justify-content-between mb-3">
                                                <div className="w-100">
                                                    <Form.Control
                                                        type="text"
                                                        id="cardExpiration"
                                                        name="cardExpiration"
                                                        required={true}
                                                        placeholder="Expiration Date* (MMYY)"
                                                        maxLength={4}
                                                        {...register('cardExpiration')}
                                                        isInvalid={errors.includes('cardExpiration')}
                                                        className="form-sub-input"
                                                        onChange={() => setErrors([])}
                                                    />
                                                    <FormControl.Feedback id="description" type="invalid">
                                                        Card Expiration Date is required
                                                    </FormControl.Feedback>
                                                </div>

                                                <div className="pl-1">
                                                    <Form.Control
                                                        type="text"
                                                        id="cvv"
                                                        name="cvv"
                                                        required={true}
                                                        placeholder="CVV*"
                                                        maxLength={4}
                                                        {...register('cvv')}
                                                        isInvalid={errors.includes('cvv')}
                                                        className="form-cvv-input"
                                                    />
                                                    <FormControl.Feedback id="description" type="invalid">
                                                        CVV is required
                                                    </FormControl.Feedback>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <p className="payment-form-required-title text-white mt-2">*Field Required</p>
                        </div>
                        <div className="button-wrapper mt-3">
                            <div className="button-of-first">
                                <Button
                                    className="mb-2"
                                    disabled={tickets.some((ticket) => ticket?.errors?.length) || errors.length}
                                    onClick={handleSubmitPayment}
                                    style={{
                                        backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                        borderColor: 'transparent',
                                    }}
                                >
                                    <i className="fas fa-lock  mr-2 "></i> SUBMIT PAYMENT
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Col>
            <Col md={4} xs={12} className="d-flex flex-column tour-details-main">
                <div
                    className="tour-details-box"
                    style={{
                        backgroundColor: `${tripSetting.mainColor}`,
                        marginTop: `${width > 768 ? '80px' : '20px'}`,
                    }}
                >
                    <h4 className="mb-4 bold" style={{ color: `${tripSetting.textColor}` }}>
                        <Row className="w-100">
                            <Col lg={8} md={8} sm={8} xs={8}>
                                {selectedTour.routeDescription}
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={4}>
                                {formatDateRange(selectedTour.startDate, selectedTour.endDate)}
                            </Col>
                        </Row>
                    </h4>

                    <h5 className="mb-2" style={{ color: `${tripSetting.textColor}` }}>
                        Pricing
                    </h5>
                    {tickets.map((ticket) => {
                        return (
                            <>
                                <Row className="w-100">
                                    <Col lg={12}>
                                        <div>
                                            <p
                                                className="bold mr-2"
                                                style={{ color: `${tripSetting.textColor}`, margin: '0px 4px' }}
                                            >{`${ticket.ticketType}`}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="w-100">
                                    <Col
                                        className="d-flex align-items-center justify-content-between"
                                        lg={8}
                                        md={8}
                                        sm={8}
                                        xs={8}
                                    >
                                        <p className="text-align-center" style={{ margin: '0px' }}>
                                            Base Ticket Price
                                        </p>
                                    </Col>
                                    <Col
                                        className="d-flex align-items-center justify-content-end"
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xs={3}
                                    >
                                        <p className="text-align-center" style={{ margin: '0px' }}>
                                            <span>{Number(ticket.price) > 0 ? `$${ticket.price}` : ''}</span>
                                        </p>
                                    </Col>
                                </Row>

                                {ticket.requiredUpgrades?.map((reqUpgrade) => (
                                    <Row key={reqUpgrade.id} className="w-100">
                                        <Col
                                            className="d-flex align-items-center justify-content-between"
                                            lg={8}
                                            md={8}
                                            sm={8}
                                            xs={8}
                                        >
                                            <p className="text-align-center" style={{ margin: '0px' }}>
                                                {reqUpgrade.availableTicketUpgrade}
                                            </p>
                                        </Col>
                                        <Col
                                            className="d-flex align-items-center justify-content-end"
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={3}
                                        >
                                            <p className="text-align-center" style={{ margin: '0px' }}>
                                                <span>
                                                    {Number(reqUpgrade.price) > 0 ? `$${reqUpgrade.price}` : ''}
                                                </span>
                                            </p>
                                        </Col>
                                    </Row>
                                ))}
                                {ticket.optionalUpgrades?.map((optionalUpgrade) => (
                                    <Row className="w-100" key={optionalUpgrade.id}>
                                        <Col
                                            className="d-flex align-items-center justify-content-between"
                                            lg={8}
                                            md={8}
                                            sm={8}
                                            xs={8}
                                        >
                                            <p className="text-align-center" style={{ margin: '0px' }}>
                                                {optionalUpgrade.availableTicketUpgrade}
                                            </p>
                                        </Col>
                                        <Col
                                            className="d-flex align-items-center justify-content-end"
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={3}
                                        >
                                            <p className="text-align-center" style={{ margin: '0px' }}>
                                                <span>
                                                    {Number(optionalUpgrade.price) > 0
                                                        ? `$${optionalUpgrade.price}`
                                                        : ''}
                                                </span>
                                            </p>
                                        </Col>
                                    </Row>
                                ))}
                                {Number(tripSetting.bookingFee) > 0 ? (
                                    <Row className="w-100">
                                        <Col className="d-flex align-items-center" lg={8} md={8} sm={8} xs={8}>
                                            <p className="text-align-center" style={{ margin: '0px' }}>
                                                Booking Fee
                                            </p>
                                        </Col>
                                        <Col
                                            className="d-flex align-items-center justify-content-end"
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={3}
                                        >
                                            <p className="text-align-center" style={{ margin: '0px' }}>
                                                <span>${Number(tripSetting.bookingFee).toFixed(2)}</span>
                                            </p>
                                        </Col>
                                    </Row>
                                ) : (
                                    ''
                                )}
                                {calculateTourTicketTaxPrice(ticket) > 0 ? (
                                    <Row className="w-100">
                                        <Col className="d-flex align-items-center" lg={8} md={8} sm={8} xs={8}>
                                            <p className="text-align-center" style={{ margin: '0px' }}>
                                                Tax
                                            </p>
                                        </Col>
                                        <Col
                                            className="d-flex align-items-center justify-content-end"
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={3}
                                        >
                                            <p className="text-align-center" style={{ margin: '0px' }}>
                                                <span>${calculateTourTicketTaxPrice(ticket)}</span>
                                            </p>
                                        </Col>
                                    </Row>
                                ) : (
                                    ''
                                )}

                                <Row className="w-100">
                                    <Col
                                        className="d-flex align-items-center justify-content-between"
                                        lg={8}
                                        md={8}
                                        sm={8}
                                        xs={8}
                                    >
                                        <p className="text-align-center bold" style={{ margin: '0px' }}>
                                            Total
                                        </p>
                                    </Col>
                                    <Col
                                        className="d-flex align-items-center justify-content-end"
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xs={3}
                                    >
                                        <p className="text-align-center" style={{ margin: '0px' }}>
                                            <span className="bold">{`$${calculateTourTicketFullPrice(ticket)}`}</span>
                                        </p>
                                    </Col>
                                </Row>
                            </>
                        );
                    })}
                    <hr className="hr-line " />
                    {Number(tripSetting?.creditCardSurchargePercentage) > 0 ? (
                        <Row className="w-100">
                            <Col
                                className="d-flex align-items-center justify-content-between"
                                lg={8}
                                md={8}
                                sm={8}
                                xs={8}
                            >
                                <p className="text-align-center" style={{ margin: '0px' }}>
                                    {`Credit Card Surcharge (${tripSetting?.creditCardSurchargePercentage}%)`}
                                </p>
                            </Col>
                            <Col className="d-flex align-items-center justify-content-end" lg={3} md={3} sm={3} xs={3}>
                                <p className="text-align-center" style={{ margin: '0px' }}>
                                    <span className="bold">{`$${calculateSurchargeAmount()}`}</span>
                                </p>
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    <Row className="w-100">
                        <Col className="d-flex align-items-center justify-content-between" lg={8} md={8} sm={8} xs={8}>
                            <p className="text-align-center bold" style={{ margin: '0px' }}>
                                Grand Total
                            </p>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-end" lg={3} md={3} sm={3} xs={3}>
                            <p className="text-align-center" style={{ margin: '0px' }}>
                                <span className="bold">{`$${calculateGrandTotal()}`}</span>
                            </p>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default TourPayment;
